import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CustomFetchBaseQuery } from '@app/types';
import { RefreshResponse, RootState } from './types';
import { Mutex } from 'async-mutex';
import { delay } from '@app/utils';
import { logout, tokensReceived } from '@app/state/actions';

const mutex = new Mutex();

function createBaseQuery() {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth?.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }) as CustomFetchBaseQuery;

  return async function baseQueryWithReauth(args, api, extraOptions) {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    const state = api.getState() as RootState;
    const refresh_token = state.auth.refreshToken || localStorage.getItem('refreshToken');
    if (
      result.error &&
      result.error.status === 401 &&
      result.error.data.errors?.message !== 'Invalid credentials'
    ) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const refreshResult = await baseQuery(
            {
              url: '/refresh-token',
              method: 'POST',
              body: {
                refresh_token,
              },
            },
            api,
            extraOptions,
          );

          if (refreshResult.data) {
            api.dispatch(tokensReceived(refreshResult.data as RefreshResponse));

            await delay(0);

            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logout());
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();

        result = await baseQuery(args, api, extraOptions);
      }
    }

    if (
      result.error &&
      (result.error.originalStatus === 502 || result.error.originalStatus === 503)
    ) {
      result.error.data = {
        message: 'The server is not available.',
        code: result.error.originalStatus.toString(),
      };
    }
    if (result?.error?.data.errors) {
      result.error.data = {
        message: result?.error?.data.errors.message,
        code: result.error.status.toString(),
      };
    }

    return result;
  } as CustomFetchBaseQuery;
}

export default createBaseQuery;
