import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

// createTheme
type Props = PropsWithChildren<unknown>;
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    delete: true;
    tertiary: true;
  }
}

// ThemeProvider
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
  },
});

export default function (props: Props) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
