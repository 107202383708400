/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createApi } from '@reduxjs/toolkit/query/react';

import createBaseQuery from './createBaseQuery';

const api = createApi({
  baseQuery: createBaseQuery(),
  endpoints: () => ({}),
});

export default api;
